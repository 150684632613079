import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`God loves us perfectly. Our suffering can yield fruits of perfection and
holiness when handed to Him. Our mistakes can become the raw substance that
forms the ladder to Heaven. Our blessings, our lives, our function are all
resting in the context of His love. Hell is alienation from God. Not because He
chose to alienate us, but because He loves us enough to give us freedom; even if
that means apart from Him; which is the poorest, smallest freedom one can have.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Though our feelings come and go, His love for us does not. It is not wearied
by our sins or our indifference; and, therefore, it is quite relentless in its
determination that we shall be cured of those sins, at whatever cost to us, at
whatever cost to Him. - C.S. Lewis`}</p>
    </blockquote>
    <h2>{`God's Love is Inexorable`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Nothing is inexorable but love. Love which will yield to prayer is imperfect
and poor. Nor is it then the love that yields, but its alloy…For love loves
unto purity. Love has ever in view the absolute loveliness of that which it
beholds. Where loveliness is incomplete, and love cannot love its fill of
loving, it spends itself to make more lovely, that it may love more; it
strives for perfection, even that itself may be perfected- not in itself, but
in the object…Therefore all that is not beautiful in the beloved, all that
comes between and is not of love’s kind, must be destroyed. And our God is a
consuming fire.`}</p>
    </blockquote>
    <p>{`God loves us such that He will spare no pain to us for the sake of our
perfection. He will also spare no pain to Himself. He weeps when we weep. He
rejoices when we rejoice. His love is perfect & unyielding.`}</p>
    <h2>{`God Loves The Real You`}</h2>
    <p>{`God loves the real you, not the infection infecting you. That is why He wills
the expulsion of our sins. We get into trouble when we consider the sin-sick,
infected us as the real us. We mistake the disease for our “self” and wonder why
God doesn’t love the disease. He can’t love the disease because He loves you.`}</p>
    <p>{`We get into trouble when we consider the sin-sick, infected neighbor as the real
person. If we could really distinguish the disease from the person love would
abound. I suppose only Jesus paid the price and is capable of that perfect
empathy & insight but that seems to put me in an even less equipped place to
judge another.`}</p>
    <h2>{`Love As A Revealer of Reality`}</h2>
    <blockquote>
      <p parentName="blockquote">{`"We must consider love as an attitude by means of which certain aspects of
reality become visible. The true meaning of the other as other, i.e. the
meaning of the other as subject, becomes visible only through love. An
attitude of preoccupation with ourselves, with our desires and interests,
precludes our access to the true meaning of the other." - William Luijpen`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`“Love reveals truth. Life is the only position or emotional disposition from
which we become fully aware of the already present reality of the other person
as more than a mere object among other objects in a crowded universe. Love
alone reveals the full reality and value of the other person.” - Terryl Givens`}</p>
    </blockquote>
    <p>{`Loving someone or something reveals more of the reality about them. For
example... while you may feel small, God’s love for you illuminates the reality
of your importance and significance. It’s almost as if in loving other people we
can help wake them (and ourselves) up to the reality of how significant and
important they are.`}</p>
    <p>{`In this way, perhaps God’s love can wake us up to the reality of who we are
called to be.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      